.App {
  text-align: center;
  text-decoration: rgb(255, 255, 255);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-logo-texto {
  height: 30vmin;
  pointer-events: none;
}

.App-ico-insta {
  height: 3vmin;
  pointer-events: none;
}


.App-header {
  background-color: #dd6c98ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ffffff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg); 
  }
}
